body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #F8F6F2;
}

#root {
	max-height: 100vh;
}

ul {
	margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}
.logoutBtn {
	font-size: 13px;
}

.cardArea p {
	margin: unset;
	font-size: 21px;
	color: #707070;
}

.rowHeader {
	font-weight: bold;
	margin-top: 1em;
	margin-bottom: 0.3em;
}

.doneBtn {
	font-weight: bold;
	color: #128dc5;
}

.doneBtn > p {
	font-weight: bold;
	color: #128dc5;
}

.warnBtn {
	color: #D20343;
	font-weight: bold;
	text-transform: uppercase;
}

.warnBtn > p {
	color: #D20343;
	font-weight: bold;
	text-transform: uppercase;
}

.centeredBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100%; */
}

.cardArea {
	max-width: 100%;
	width: 100%;
	max-height: 100%;
	height: 100%;
	padding-top: 2em;
	padding-right: 1em;
	padding-left: 1em;
	padding-bottom: 1em;
	overflow: hidden;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.signInIcon {
	width: 42px;
	height: 42px;
	min-width: 42px;
	min-height: 42px;
	background-position-x: 5px;
    background-position-y: 50%;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: transparent;
}

.signInBtnText {
	padding-left: 42px;
	padding-right: 42px;
	color: #444;
	font-size: 14px;
	font-weight: bold;
	/* Use the Roboto font that is loaded in the <head> */
	font-family: 'Roboto', sans-serif;
}

.signInBtn {
	display: flex;
	align-items: center;
	justify-content: left;
    background: white;
	width: 190px;
    border-radius: 0.3em;
    border: thin solid #707070;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}

input[type=text] {
	border: none;
	border-radius: 0em;
	border-bottom: 2px solid #707070;
	font-size: 16px;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
}
input[type=text]:focus {
	box-shadow: none;
	outline: none;
	border: none;
	font-size: 16px;
	border-bottom: 3px solid #555;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
}

.linkText {
	font-size: 16px;
}

.connectText {
	font-size: 14px;
}


textarea ,
textarea:focus {
	margin-top: 0.2em;
	box-shadow: none;
	outline: none;
	border: none;
	resize: none;
	width: 100%;
	font-size: 16px;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	white-space: pre;
	text-decoration: underline;
	color: #128dc5;
}

.consentPage {
    padding: 1em;
    display: flex;
    flex-direction: column;
    /* height: 100vh;
    min-height: 100vh; */
	align-items: center;
	margin-top: 30%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.game-rules {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.game-rules .container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.game-rules h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.game-rules .section {
  margin-bottom: 20px;
}

.game-rules .section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.game-rules .section p {
  margin: 10px 0;
}

.game-rules ul {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
}
